<template>
  <!-- container -->
  <div class="vue-drawer">
    <div
      id="drawer"
      ref="drawer"
      class="drawer scrollbar"
      role="dialog"
      :class="[
        pos != 'left' ? 'drawer-right' : 'drawer-left',
        show ? 'active' : '',
        draftHeader ? 'overlap-header' : '',
        wide ? 'drawer-wide' : '',
      ]"
    >
      <!-- drawer -->
      <div :class="draftHeader ? 'header-draft' : ''" />
      <header class="drawer-header">
        <div class="header-left">
          <slot name="header-left">
            <AulaButton
              class="d-flex d-lg-none"
              variant="link"
              fill="clear"
              :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
              @click="hideMask"
            >
              <Icon :name="iconClassEnum.APP_ARROW_SLIDEBACK" />
            </AulaButton>
          </slot>
        </div>
        <div class="drawer-title"><slot name="drawer-title" /></div>
        <div class="header-right">
          <slot name="header-right" />
          <AulaButton
            class="d-flex"
            variant="link"
            fill="clear"
            :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
            @click="hideMask"
          >
            <Icon class="aula-close" :name="iconClassEnum.CLOSE" />
          </AulaButton>
        </div>
      </header>
      <div class="drawer-content scrollbar">
        <slot name="drawer-content" />
      </div>
    </div>
    <div class="main">
      <!-- mask -->
      <div class="mask" :class="show ? 'active' : ''" @click="hideMaskIfShown" />
    </div>
  </div>
</template>

<script scoped>
import $ from 'jquery';
import AulaButton from './AulaButton.vue';
import Icon from './Icon.vue';
import { iconClassEnum } from '../enums/iconClassEnum';

export default {
  components: { Icon, AulaButton },
  props: {
    containerSelectorOutSide: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    pos: {
      type: String,
      default: 'right',
    },
    tran: {
      type: String,
      default: 'overlay',
    },
    draftHeader: {
      type: Boolean,
      default: true,
    },
    focusTarget: {
      type: String,
      default: 'input[type=text]',
    },
    isScrollTop: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
  },
  watch: {
    show: function () {
      // listener
      const transitionTimeInMs = 500;
      if (!this.isScrollTop) {
        $('html').removeClass('aula-open-do-not-reset-position');
      }
      $('html').removeClass('drawer-open');
      this.$el.classList.remove('active');
      if (this.containerSelectorOutSide.length > 0) {
        $(this.containerSelectorOutSide).removeClass('hide-container');
      }
      if (!this.show) {
        setTimeout(() => {
          this.$emit('on-hide');
        }, transitionTimeInMs);
        if (document.querySelector('.upload-area .aula-modal-container .modal-header .aula-close')) {
          document.querySelector('.upload-area .aula-modal-container .modal-header .aula-close').click();
        }
        $('.modal-aula').removeClass('hide-scrollbar');
      } else {
        // Hide all tooltips when opening
        this.$root.$emit('bv::hide::tooltip');

        if (!this.isScrollTop) {
          $('html').addClass('aula-open-do-not-reset-position');
        } else {
          $(window).scrollTop(0);
        }
        $('html').addClass('drawer-open');
        this.$el.classList.add('active');
        $('.modal-aula').addClass('hide-scrollbar');
        if (this.containerSelectorOutSide.length > 0) {
          $(this.containerSelectorOutSide).addClass('hide-container');
        }
        this.$emit('on-show');
        $('.drawer').hide().show(0);
        this.$nextTick(() => {
          setTimeout(() => {
            const focusableElement = this.$el.querySelector(this.focusTarget);
            if (focusableElement) {
              focusableElement.focus();
            }
          }, transitionTimeInMs);
        });
      }
    },
  },
  mounted() {
    const em = this;
    document.onkeydown = function (e) {
      e = e || window.event;
      const charCode = typeof e.which == 'number' ? e.which : e.keyCode;
      if (charCode == 27) {
        em.hideMaskIfShown();
      }
      if (e.target.type == 'textarea') {
        $('.drawer').hide().show(0);
      }
    };
  },
  beforeDestroy() {
    $('html').removeClass('drawer-open');
  },
  methods: {
    hideMask() {
      this.$emit('change-show', false);
    },
    hideMaskIfShown() {
      if (this.show) {
        this.hideMask();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.header-right {
  display: flex;
}
.header-right,
.header-left {
  width: 2rem;
}
.drawer-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

@include breakpoint-lg() {
  .header-left {
    display: none;
  }
  .header-right,
  .header-left {
    --btn-link-color: var(--color-white);

    width: auto;
    color: var(--color-white);
  }
  .drawer-title {
    font-weight: normal;
    justify-content: flex-start;
  }
}
</style>
